import React, { useCallback, useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import ReactPixel from "react-facebook-pixel";
import { useLocation, Link } from "react-router-dom";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Default, Mobile } from "../../helpers/Breakpoints";
import { loadRazorpay } from "../../modules/payments/Razorpay";
import {
  fetchPartyById,
  getParticipantCountAndMembersLimit,
  sendOtpWhatsapp,
  signupUser,
  verifyUser,
} from "../../../api/requests";
import checked from "../../../assets/ic_checked.png";
import coupon from "../../../assets/ic_coupon.png";
import { applyCouponCode } from "../../../api/requests";
import styles from "../../modules/auth/Auth.module.scss";
import avatar from "../../../assets/square_logo.png";

import { useQuery } from "react-query";
import whatsappImg from "../../../assets/whatsapp.png";
import discoBall from "../../../assets/image 7.svg";

import {
  RenderUserObj,
  RenderDate,
  Loader,
  calculateDiscount,
  ScrollToTop,
} from "../../helpers";
import BookTicket from "../../modules/common/parties/BookTicket";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { forEach, forIn } from "lodash";
import { updateGuestCount } from "../../../actions/guestBookActions";
import { authenticateUserRequest } from "../../../actions/authActions";
import { debounce, getLoader, regex, showErrorToast, showSuccessToast } from "../../../utils/utils";
import quirkyArrow from "../../../assets/Group (1).png";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { isEmptyObject } from "jquery";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default function BookParty() {
  const [couponCode, setCouponCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [allowedDiscount, setAllowedDiscount] = useState(0);
  const [message, setMessage] = useState("");
  const [couponLimit, setCouponLimit] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [ticketModal, setTicketModal] = useState(false);
  const [ticketsUpdated, setTicketsUpdated] = useState(false);
  const [currentTickets, setCurrentTickets] = useState(0);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const guestCount = useSelector((state) => state.guestBook.guestCount);

  const [isUserExist, setIsUserExist] = useState(false);
  const [isSilentLogin, setIsSilentLogin] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);

  const dispatch = useDispatch();

  const bookTicketRef = useRef();

  const [eventMembersCount, setEventMembersCount] = useState(0);
  const [participantCount, setParticipantCount] = useState(0);
  const [remove, setRemove] = useState(false);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const inputRefs = useRef([]);

  const handleGoBack = () => {
    history.goBack(); // Go back to the previous page in history
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const handleTopAndClose = () => {
    scrollToTop();
    setBottomActive(false);
  };

  const handleOtpChange = (value, index) => {
    if (isNaN(value)) return; // Only allow numeric values
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerify = debounce(async () => {
    // Add verify code logic here
    try {
      setLoading(true);
      let request = {
        phone: guests[0]?.guest_mobile,
        otp: otp.join(""),
      };
      const response = await verifyUser(request);
      if (response.status === "success") {
        setOtp(["", "", "", "", "", ""]);
        if (response?.isUserExist) {
          dispatch(authenticateUserRequest(response));
          let tempGuests = [...guests];

          tempGuests[0].guest_email = response.data.email;
          tempGuests[0].guest_name = response.data.fullName;
          tempGuests[0].guest_gender = response.data.gender;
          setIsEmailExist(emailRegex.test(response.data.email));
          setIsUserExist(true);
          setGuests(tempGuests);
          getPartyDetails();
        }
        setIsSilentLogin(true);
        setShow(false);
      } else {
        showErrorToast(response?.message ?? "Something went wrong")
      }
    } catch {

    } finally {
      setLoading(false);
    }
  }, 500);

  const handleResend = () => {
    // Add resend code logic here
    console.log("Code resent");
    setResendDisabled(true);
    handleOtpSend();
    setTimeout(() => setResendDisabled(false), 30000); // Re-enable after 60 seconds
  };

  let userObj = useSelector((state) => state.auth.data);

  userObj = isAuthenticated ? JSON.parse(userObj) : [];

  const authState = useSelector((state) => state.auth);

  let [showtext, setShowText] = useState(false);
  let [bottomActive, setBottomActive] = useState(false);

  let [guests, setGuests] = useState([]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [error, setError] = useState(false);

  // Function to validate guest details
  const validateGuestDetails = () => {
    for (const guest of guests) {
      if (!guest.guest_name || !guest.guest_mobile || !guest.guest_gender) {
        setError(true);
        return;
      }
    }
    setError(false);
    // Proceed with payment logic here
  };

  const token = (userObj && userObj.token) || null;
  const { id } = useParams();
  const history = useHistory();

  // const {  } = useQuery([id], fetchPartyById, {
  //   refetchOnWindowFocus: false,
  // });
  let amount = ((data && data?.data?.amount) || 0) * guests.length;

  let TaxAmount = parseFloat(amount * 0.05 + amount * 0.05 * 0.18);

  let finalAmount = parseFloat(amount + TaxAmount);
  let sessionTimer;
  useEffect(() => {
    getPartyDetails()
    loadRazorpay();
    ReactPixel.init("1890035134379309");
    ReactPixel.track("PageView");
    if (data && data?.data?.membersLimit <= 1) checkGuestCount();
    timerSet();
    return () => {
      clearTimeout(sessionTimer);
    };
  }, []);

  const getPartyDetails = async () => {
    try {
      setLoading(true);
      const response = await fetchPartyById(id);
      if (response.status === "success") {
        if (response?.data?.bookingStatus === 3) {
          history.goBack();
          return;
        }

        // console.l
        setData(response);
      } else {
        showErrorToast(response.message ?? "Something went wrong")
      }

    } catch {

    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // Update coupon discount on adding guests
    if (data !== undefined && couponCode !== "") {
      let discount = calculateDiscount(
        couponLimit,
        allowedDiscount,
        data?.data?.amount,
        guests.length
      );
      setCouponDiscount(discount);
    }
    // let data?.data?.membersLimit = 4;
    if (data !== undefined) {
      if (guests.length >= data?.data?.membersLimit) {
        checkGuestCount();
        timerSet();
      }
    }
  }, [guests]);


  useEffect(() => {
    let userData = userObj?.data;

    console.log("ASDFADSf", typeof userData);
    if (userObj?.data) {
      setIsUserExist(true);
      setIsEmailExist(emailRegex?.test(userData.email));
    }

    console.log("guestCount", guestCount, guests.length);
    let tempGuests = [...guests];

    if (guestCount === 1 && !isEmptyObject(userData)) {
      console.log("guestCount === 1");

      setGuests([
        {
          id: userData?.eventId,
          guest_name: userData?.fullName,
          guest_mobile: userData?.phone,
          guest_gender: userData?.gender,
          guest_email: userData?.email,
          isSelected: false,
        },
      ]);
    } else if (guestCount < guests.length) {
      tempGuests.pop();
      setGuests(tempGuests);
    } else if (guestCount > guests.length) {
      for (let i = guests.length; i < guestCount; i++) {
        console.log("push");
        if (i === 0) {
          tempGuests.push({
            id: userData?.eventId,
            guest_name: userData?.fullName,
            guest_mobile: userData?.phone,
            guest_gender: userData?.gender,
            guest_email: userData?.email,
            isSelected: false,
          });
        } else {
          tempGuests.push({
            id: new Date(),
            guest_name: "",
            guest_mobile: "",
            guest_gender: "",
            guest_email: "",
            isSelected: i == 1 ? true : false,
          });
        }
      }

      setGuests(tempGuests);
      // console.log(guests, "Guests")
    }

    // if (guestCount > 1) {
    //   let newGuests = {
    //     id: new Date(),
    //     guest_name: '',
    //     guest_mobile: '',
    //     guest_gender: '',
    //     guest_email: '',
    //   }

    //   let guestsArray = [];

    //   for (let index = 0; index < guestCount - 1; index++) {
    //     guestsArray.push(newGuests);
    //   }

    //   setGuests((prev) => [
    //     ...prev,
    //     ...guestsArray
    //   ]);

    // }
  }, [guestCount, isAuthenticated]);

  const sessionTimeout = () => {
    // If the tickets are less than the guest count then it opens the warning modal
    if (data) {
      if (guests.length > data?.data?.membersLimit) {
        setTicketModal(true);
      }
    }
  };

  const checkCheckoutTicketsAvailable = () => {
    fetchPartyById(id).then((res) => {
      setCurrentTickets(res.data.membersLimit);
      setTicketsUpdated(true);
    });
    setTicketModal(true);
  };

  const timerSet = () => {
    // Timer starts and after 2 minutes modal comes up
    function finalCount() {
      sessionTimer = setTimeout(() => {
        if (data) {
          if (guests.length > data?.data?.membersLimit) {
            setTicketModal(true);
          } else {
            timerSet();
          }
        }
      }, 120000);
    }
    finalCount();
  };

  const handleTicketModal = () => {
    setTicketModal(false);
  };

  const handleTicketReroute = () => {
    sessionTimer && clearTimeout(sessionTimer);
    // if (data?.data?.membersLimit > 0) {
    if (data?.data?.membersLimit > 0) {
      handleTicketModal();
      window.location.reload();
    } else history.push(`/parties?page=1`);
  };

  const checkGuestCount = () => {
    console.log("hello");
    // if (data !== undefined) {
    //   if (guests.length <= data?.data?.membersLimit) {
    //     setErrorMessage(
    //       "Cannot book for more people! This House Party is almost sold out."
    //     );
    //   }
    // }
  };
  // console.log(sessionTimer);
  // function AddGuest() {
  //   // console.log("added");
  //   // setGuests([...guests, { id: Date.now(), name: "", phone: "" }]);
  //   // // console.log(guests);
  //   // setGuests((prev) => [...prev, { id: Date.now(), name: "", phone: "" }]);
  //   // console.log(guests);
  // }
  function RemoveGuest(id) {
    setGuests(guests.filter((guest) => guest.id !== id));
    handleClickRemove();
  }

  const handleClickRemove = () => {
    setCouponDiscount(0);
    setCouponCode("");
  };



  const handleBooking = () => {
    setShowLoader(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = { month: "long", day: "numeric", weekday: "long" };

    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (time) => {
    if (!time) return "";
    const date = new Date();
    const [hours, minutes] = time.split(":");
    date.setHours(hours);
    date.setMinutes(minutes);

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString("en-US", options);
  };

  const handleInputOnChange = (e, guest) => {
    e.persist();
    setGuests((prev) =>
      prev.map((item) => {
        if (item.id === guest.id) {
          return {
            ...item,
            [e.target.name]: e.target.value,
          };
        } else {
          return { ...item };
        }
      })
    );
  };

  const getGuestView = (guest, index) => {
    return (
      <div className="guestsWrapper purely">
        <div
          className="guestHead"
          onClick={() =>
            setGuests((prev) =>
              prev.map((item) => {
                if (item?.id === guest?.id) {
                  return {
                    ...item,
                    isSelected: !item?.isSelected,
                  };
                } else {
                  return { ...item };
                }
              })
            )
          }
        >
          <div className="guestTit">
            <h2>{`Guest ${index + 1}`}</h2>

            <button
              onClick={() => {
                if (guests.length > 1) {
                  let tempGuest = [...guests];
                  console.log("index", index);
                  tempGuest.splice(index, 1);
                  console.log("tempGuest", tempGuest);

                  dispatch(updateGuestCount(guestCount - 1));
                  setGuests(tempGuest);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-trash"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 7l16 0" />
                <path d="M10 11l0 6" />
                <path d="M14 11l0 6" />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
              </svg>
            </button>
          </div>

          {guest?.isSelected ? (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-up"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 15l6 -6l6 6" />
              </svg>
            </div>
          ) : (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 9l6 6l6 -6" />
              </svg>
            </div>
          )}
        </div>

        {guest?.isSelected ? (
          <>
            <div className="guestWhatsapp">
              <input
                type="text"
                name="guest_mobile"
                onChange={(e) => handleInputOnChange(e, guest)}
                placeholder="Enter WhatsApp Mobile Number"
                value={guest.guest_mobile}
              />
              <p>We’ll send verification code & Party updates on WhatsApp</p>
            </div>

            {/* <div className="inputContsFill">
              <label htmlFor="">Contact Info</label>

              <input
                type="text"
                name="guest_email"
                placeholder="Email"
                value={guest.guest_email}
                onChange={(e) => handleInputOnChange(e, guest)}
              />
            </div> */}
            <div className="inputContsFill">
              <label htmlFor="">Name</label>

              <input
                type="text"
                placeholder="Full name"
                value={guest.guest_name}
                name="guest_name"
                onChange={(e) => handleInputOnChange(e, guest)}
              />
            </div>
            <div className="inputContsFill">
              <label htmlFor="">Gender</label>

              <select
                name="guest_gender"
                id=""
                onChange={(e) => handleInputOnChange(e, guest)}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  const handleBottomSheet = () => {
    setBottomActive(!bottomActive);
    scrollToTop();
    console.log(true);
  };

  const handleOtpSend = debounce(() => {
    setLoading(true);
    sendOtpWhatsapp({ phone: guests[0]?.guest_mobile }).then((res) => {
      if (res.status === "success") {
        showSuccessToast(res?.message || "Otp sent successfully");
        setShow(true);
      } else {
        showErrorToast(res?.message ?? "Something went wrong");
      }
    }).catch(() => { }).finally(() => {
      setLoading(false);
    });
  }, 500);

  const handleCreateOrder = debounce(async () => {

    try {
      setLoading(true);

      if (isSilentLogin) {

        const token = localStorage.getItem("tokenNew");

        if (token) {
          bookTicketRef.current.handlePaymentFromParent();
          return;
        }
        const apiRequest = {
          fullName: guests[0].guest_name,
          phone: guests[0].guest_mobile,
          email: guests[0].guest_email,
          gender: guests[0].guest_gender
        }


        const response = await signupUser(apiRequest)

        if (response.status === "success") {
          dispatch(authenticateUserRequest(response));
          bookTicketRef.current.handlePaymentFromParent();
        }
        else {
          if (response?.isSessionExpired) {
            showErrorToast(response?.message ?? "Something went wrong");
            handleOtpSend();
          } else if (response?.isUserExist) {
            bookTicketRef.current.handlePaymentFromParent();
          } else {
            showErrorToast(response?.message ?? "Something went wrong");
          }
        }
      } else {
        bookTicketRef.current.handlePaymentFromParent();
      }
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }, 500);



  return (
    <>
      {loading &&
        getLoader()
      }
      <ScrollToTop />

      <Modal show={show} centered backdrop="static" className="whatsappOtpMod">
        <Modal.Header>
          <Modal.Title>Confirm Your Number</Modal.Title>
          <div onClick={() => setShow(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-x"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="messageNum">
            <p>Enter the code sent on {guests[0]?.guest_mobile}</p>
          </div>
          <Form className="whatsappNumForm">
            <Row className="mb-3">
              {otp.map((value, index) => (
                <Col key={index}>
                  <Form.Control
                    type="number"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    className="text-center otpInputs"
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                </Col>
              ))}
            </Row>

            <Button variant="primary" onClick={handleVerify} block>
              Verify Code
            </Button>
            <Button
              variant="link"
              onClick={handleResend}
              disabled={resendDisabled}
              block
            >
              Resend Code
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={false} centered backdrop="static" className="succesorder">
        <div>
          <img src={discoBall} alt="" className="absoluteImg" />

          <h2 className="suctit">Yay, We’ve got your request !</h2>

          <div className="eventDetailsSuccess">
            <div className="flexCenter gap15">
              <div className="smolCardImg"></div>

              <div className="rightEventDetails">
                <h1>{data?.data?.title}</h1>

                <div className="eventDetGap">
                  <div className="locationNdate flexCenter">
                    <div className="dateCont flexCenter">
                      <div className="iconSvg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                          <path d="M16 3l0 4" />
                          <path d="M8 3l0 4" />
                          <path d="M4 11l16 0" />
                          <path d="M8 15h2v2h-2z" />
                        </svg>
                      </div>
                      <p className="ellipsis">
                        {" "}
                        {formatDate(data?.data?.eventDate)}
                      </p>
                    </div>
                    <div className="locationCont flexCenter">
                      <div className="iconSvg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                        </svg>
                      </div>
                      <p className="ellipsis">
                        {data?.data?.location} {","} {data?.data?.city}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="verySmallP">
            You will receive Party location 2 hrs before the party on Whatsapp
            as well as on email.
          </p>

          <div className="profileVerificationCont">
            <h4>
              It’s mandatory to verify your profile before attending the party.
            </h4>
            <button className="whiteFull">Let's verify Profile</button>
          </div>
        </div>
      </Modal>

      <Modal
        backdrop="static"
        show={ticketModal}
        onHide={handleTicketModal}
        centered
      >
        <button
          className={`${styles.closebtn} ${data?.data?.membersLimit > 0 ? "d-none" : "d-block"
            } bg-primary text-white rounded border-0`}
          onClick={handleTicketReroute}
        >
          X
        </button>
        <Container className=" text-center py-5">
          <img
            src={avatar}
            alt="payment successful playace"
            height="150"
            width="150"
          />
          <h3 className="fw-bold text-uppercase">
            {data?.data?.membersLimit > 0
              ? "Request timed out"
              : "Party sold out"}
          </h3>
          <p>
            {data?.data?.membersLimit > 0
              ? `Oops.. the party is almost sold out. Only ${data?.data?.membersLimit > 1
                ? `${ticketsUpdated
                  ? currentTickets
                  : data?.data?.membersLimit
                } invites`
                : `${ticketsUpdated
                  ? currentTickets
                  : data?.data?.membersLimit
                } invite`
              }   left`
              : "The party you were trying to book has been sold out."}
          </p>
          <button
            onClick={handleTicketReroute}
            className="btn btn-reg btn-primary fw-bold btn-padding"
          >
            {data?.data?.membersLimit > 0 ? "Okay" : "Explore more parties"}
          </button>
        </Container>
      </Modal>
      {/* <Default> */}

      <Mobile>
        <div className="topFakeNav" onClick={handleGoBack}>
          <div className="backIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
          </div>
          <p>Confirm & Pay</p>
        </div>
      </Mobile>

      <Container style={{ minHeight: "60vh" }} className="marMore mobileTopone">
        <Row className="justify-content-between">
          <Col md={7}>
            <div className="partyHeading">
              <h1>{data?.data?.title}</h1>

              <div className="eventDetGap">
                <div className="locationNdate flexCenter">
                  <div className="dateCont flexCenter">
                    <div className="iconSvg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                        <path d="M16 3l0 4" />
                        <path d="M8 3l0 4" />
                        <path d="M4 11l16 0" />
                        <path d="M8 15h2v2h-2z" />
                      </svg>
                    </div>
                    <p> {formatDate(data?.data?.eventDate)}</p>

                    <span className="line"></span>

                    <p>
                      {formatTime(data?.data?.startTime)} to{" "}
                      {formatTime(data?.data?.endTime)}
                    </p>
                  </div>
                  <div className="locationCont flexCenter">
                    <div className="iconSvg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                      </svg>
                    </div>
                    <p>
                      {data?.data?.location} {","} {data?.data?.city}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Mobile>
              <div className="upperFake">
                <BookTicket
                  bottomActivate={handleBottomSheet}
                  view="receipt"
                  hideGstNall={true}
                  classes="shadow"
                  amount={amount}
                  TaxAmount={TaxAmount}
                  couponDiscount={couponDiscount}
                  setCouponCode={setCouponCode}
                  setCouponDiscount={setCouponDiscount}
                  couponCode={couponCode}
                  isAuthenticated={isAuthenticated}
                  userObj={userObj}
                  party={data}
                  setError={setError}
                  guestCount={guests.length}
                  guests={guests}
                  finalAmount={finalAmount}
                  validateGuestDetails={validateGuestDetails}
                  isBooking={handleBooking}

                  handleTicket={checkCheckoutTicketsAvailable}
                  handleClickRemove={handleClickRemove}
                  error={error}
                  ref={bookTicketRef}
                />
              </div>
            </Mobile>
            {/* <Mobile>
              <div className={`nonSticky ${bottomActive ? "show" : ""}`}>
                <div
                  className="closeBottomSheet"
                  onClick={() => handleTopAndClose()}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M18 6l-12 12" />
                      <path d="M6 6l12 12" />
                    </svg>
                  </span>
                </div>

                <BookTicket
                  fakeContinue={true}
                  closeNall={handleTopAndClose}
                  view="receipt"
                  classes="shadow"
                  amount={amount}
                  TaxAmount={TaxAmount}
                  couponDiscount={couponDiscount}
                  setCouponCode={setCouponCode}
                  setCouponDiscount={setCouponDiscount}
                  couponCode={couponCode}
                  isAuthenticated={isAuthenticated}
                  userObj={userObj}
                  party={data}
                  setError={setError}
                  guestCount={guests.length}
                  guests={guests}
                  finalAmount={finalAmount}
                  validateGuestDetails={validateGuestDetails}
                  isBooking={handleBooking}
                  handleReroute={handleReroute}
                  handleTicket={checkCheckoutTicketsAvailable}
                  handleClickRemove={handleClickRemove}
                  error={error}
                  ref={bookTicketRef}
                />
              </div>
            </Mobile> */}

            <div className="newFlowRevam">
              <h3>Your Details</h3>

              {/* <div className="guestListInputs"> */}

              <>
                {guests.map((guest, index) => {
                  if (index === 0) {
                    return (
                      <>
                        {!isUserExist && !isSilentLogin && (
                          <div className="whatsappFlow">
                            <div className="firstTimeFlow">
                              <div className="relative">
                                <div className="quirkyText">
                                  <img src={quirkyArrow} alt="" />
                                  <p>
                                    Dont worry, We won’t spam you :) - We
                                    promise
                                  </p>
                                </div>
                                <input
                                  type="text"
                                  placeholder="Enter WhatsApp Mobile Number"
                                  name="guest_mobile"
                                  value={guest?.guest_mobile}
                                  onChange={(e) =>
                                    handleInputOnChange(e, guest)
                                  }
                                  disabled={isUserExist}
                                />

                                <img
                                  src={whatsappImg}
                                  alt=""
                                  className="whatsappImgAb"
                                />
                              </div>

                              <p>
                                You’ll receive the important party status on
                                this whatsapp number.
                              </p>

                              <a
                                className="btn btn-reg btn-primary fw-bold btn-padding d-inline-block w-full"
                                onClick={() => handleOtpSend()}
                              >
                                Send Verification code
                              </a>
                            </div>
                          </div>
                        )}

                        {(isUserExist || isSilentLogin) && (
                          <>
                            <div className="whatsappFlow">
                              <div className="verifiedCont">
                                <div className="showVerified">
                                  <div className="whatsappLogo">
                                    <img src={whatsappImg} alt="" />
                                  </div>

                                  <div className="whatsNum">
                                    <p>WhatsApp Number</p>
                                    <p>+91 {guest?.guest_mobile}</p>
                                  </div>
                                </div>
                                <p>
                                  You’ll receive the important party updates on
                                  this number.
                                </p>
                              </div>
                            </div>

                            <div className="mainGuest">
                              <div className="guestsWrapper">
                                <div className="inputContsFill">
                                  <label htmlFor="">Contact Info</label>

                                  <input
                                    type="text"
                                    placeholder="Email"
                                    name="guest_email"
                                    value={guest?.guest_email}
                                    onChange={(e) =>
                                      handleInputOnChange(e, guest)
                                    }
                                    disabled={isEmailExist && isUserExist}
                                  />
                                </div>
                                <div className="inputContsFill">
                                  <label htmlFor="">Name</label>

                                  <input
                                    type="text"
                                    placeholder="Your full name"
                                    name="guest_name"
                                    value={guest?.guest_name}
                                    onChange={(e) =>
                                      handleInputOnChange(e, guest)
                                    }
                                    disabled={guest?.guest_name && isUserExist}
                                  />
                                </div>
                                <div className="inputContsFill">
                                  <label htmlFor="">Gender</label>

                                  <select
                                    name="guest_gender"
                                    id=""
                                    value={guest?.guest_gender?.toLowerCase()}
                                    onChange={(e) =>
                                      handleInputOnChange(e, guest)
                                    }
                                    disabled={
                                      guest?.guest_gender && isUserExist
                                    }
                                  >
                                    <option value="gender">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    );
                  }
                  if (isUserExist || isSilentLogin)
                    return getGuestView(guest, index);
                })}

                {(isUserExist || isSilentLogin) && (
                  <div className="payNowCont">
                    <button
                      onClick={handleCreateOrder}
                      className={`btn btn-reg btn-primary btn-padding d-inline-block w-full`}
                    >
                      {"Confirm & Pay"}
                    </button>
                    <p>
                      By clicking this button you accept to our
                      <NavLink to="/terms">Terms and Conditions</NavLink>
                    </p>
                  </div>
                )}
              </>

              {/* </div> */}
            </div>

            <div className="d-flex flex-column">
              <small className="text-primary my-2 cannotMore">
                {errorMessage}
                {/* {guests.length >= data?.data?.membersLimit ||
                data?.data?.membersLimit <= guests.length + participantCount
                  ? "Cannot book for more people! This House Party is almost sold out."
                  : null} */}
              </small>
            </div>
          </Col>
          <Col md={4}>
            {/* //THIS IS */}
            <Default>
              <BookTicket
                view="receipt"
                classes="shadow"
                amount={amount}
                TaxAmount={TaxAmount}
                couponDiscount={couponDiscount}
                setCouponCode={setCouponCode}
                setCouponDiscount={setCouponDiscount}
                couponCode={couponCode}
                isAuthenticated={isAuthenticated}
                userObj={userObj}
                party={data}
                setError={setError}
                guestCount={guests.length}
                guests={guests}
                finalAmount={finalAmount}
                validateGuestDetails={validateGuestDetails}
                isBooking={handleBooking}

                handleTicket={checkCheckoutTicketsAvailable}
                handleClickRemove={handleClickRemove}
                error={error}
                ref={bookTicketRef}
              />
            </Default>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import React, { useEffect, useState } from "react";
import CarouselSlider from "react-carousel-slider";
import { bannerImages } from "../../../assets/banner-images/index";
import { getAdBanners } from "../../../api/requests";

function AdBanners({ isEvent = true }) {
  const data = [
    {
      id: 1,
      image: bannerImages.Banner1,
    },
    {
      id: 2,
      image: bannerImages.Banner2,
    },
    {
      id: 3,
      image: bannerImages.Banner3,
    },
    {
      id: 4,
      image: bannerImages.Banner4,
    },
    {
      id: 5,
      image: bannerImages.Banner5,
    },
  ];

  let manner = {
    autoSliding: { interval: "5s" },
    duration: "1s",
  };

  let sliderBoxStyle = {
    height: "200px",
    width: "100%",
    background: "transparent",
    border: "none",
  };

  let itemsStyle = {
    height: "100%",
    width: "100%",
    background: "transparent",
    border: "none",
    margin: "0px 8px",
    borderRadius: "10px",
  };

  let textBoxStyle = {
    display: "none",
  };

  let buttonSetting = {
    style: {
      left: {
        display: "none",
      },
      right: {
        display: "none",
      },
    },
  };

  let dotsSetting = {
    placeOn: "bottom",
    style: {
      dotSize: "5px",
      currDotColor: "#FF1844",
      marginTop: "2px",
    },
  };

  // let webBanners = banners && banners.slice(0, 6);

  const [webBanners, setWebBanners] = useState([]);

  useEffect(() => {
    callApi();
  }, []);

  const callApi = async () => {
    const response = await getAdBanners(isEvent);
    console.log("getAdBanners response", response);
    if (response.status === "success") {
      console.log(
        "response.data.map((banner) => banner.image)",
        response?.data?.map((banner) => banner.image)
      );
      setWebBanners(
        response?.data?.map((banner) => {
          return {
            ...banner,
            imgSrc: banner.image,
          };
        })
      );
    }
  };

  let banners =
    webBanners &&
    webBanners.map(function (item, index) {
      return (
        <a href={item.link} target="_blank" rel="noopener noreferrer">
          <img
            src={item.imgSrc}
            alt="playace parties spaces house parties"
            width="522"
            height="294"
          />
        </a>
      );
    });

  // console.log(webBanners);

  return (
    <div className="slider">
      {webBanners.length > 0 && (
        <>
          {webBanners.length === 1 ? (
            <div>
              <a
                href={webBanners[0].link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "flex" }}
              >
                <img
                  src={webBanners[0].imgSrc}
                  alt="playace parties spaces house parties"
                  width="100%"
                  height="auto"
                  className="tempImgS"
                />
              </a>
            </div>
          ) : (
            <CarouselSlider
              slideCpnts={banners}
              manner={manner}
              sliderBoxStyle={sliderBoxStyle}
              dotsSetting={dotsSetting}
              buttonSetting={buttonSetting}
              itemsStyle={itemsStyle}
              textBoxStyle={textBoxStyle}
            ></CarouselSlider>
          )}
        </>
      )}
    </div>
  );
}

export default AdBanners;

// import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
// import { bannerImages } from "../../../assets/banner-images/index";
// import { getAdBanners } from "../../../api/requests";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// function AdBanners({ isEvent = true }) {
//   const data = [
//     {
//       id: 1,
//       image: bannerImages.Banner1,
//     },
//     {
//       id: 2,
//       image: bannerImages.Banner2,
//     },
//     {
//       id: 3,
//       image: bannerImages.Banner3,
//     },
//     {
//       id: 4,
//       image: bannerImages.Banner4,
//     },
//     {
//       id: 5,
//       image: bannerImages.Banner5,
//     },
//   ];

//   const [webBanners, setWebBanners] = useState([]);

//   useEffect(() => {
//     callApi();
//   }, []);

//   const callApi = async () => {
//     const response = await getAdBanners(isEvent);
//     console.log("getAdBanners response", response);
//     if (response.status === "success") {
//       setWebBanners(
//         response?.data?.map((banner) => {
//           return {
//             ...banner,
//             imgSrc: banner.image,
//           };
//         })
//       );
//     }
//   };

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     arrows: true,

//   };

//   return (
//     <div className="slider">
//       {webBanners.length > 0 && (
//         <Slider {...settings}>
//           {webBanners.map((item, index) => (
//             <>
//               <div key={index}>
//                 <a href={item.link} target="_blank" rel="noopener noreferrer">
//                   <img
//                     src={item.imgSrc}
//                     alt="playace parties spaces house parties"
//                     width="100%"
//                     height="auto"
//                     className="tempImgS"
//                   />
//                 </a>
//               </div>
//             </>
//           ))}
//         </Slider>
//       )}
//     </div>
//   );
// }

// export default AdBanners;
